@import url(https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap);
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'ABeeZee', sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 1000px) {
  .App-logo{
    height: 50vmin;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    -webkit-animation: App-logo-spin infinite 20s linear;
  }

}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.home1 {
    background: linear-gradient(to top, rgba(183, 152, 145, 0.9), rgba(148, 113, 107, 0.9)), url(/static/media/home1.25259e9c.jpg);
    min-height: 100%;
    background-size: cover;
    background-position: 50% 0;
}

/* second */
.second {
    padding: 50px 7% 50px;
}

.center {
    text-align: center;
}

.both-sections {
    display: flex;
    align-items: center;
}

.left-section {
    width: 50%;
}

.right-section {
    width: 50%;
}

.second__heading {
    margin: 50px auto 120px;
    font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
    font-weight: 800;
    font-size: 50px;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 2px;
    background-image: linear-gradient(to right, #000, #000);
    color: transparent;
    -webkit-background-clip: text;
    transition-duration: .5s;
    /* animation: pulse .2s infinite; */
}

/* @-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 5px 5px 20px rgba(51, 51, 51, 0.8);
    }

    70% {
        -webkit-box-shadow: 5px 5px 20px rgba(19, 18, 18, 0.878);
    }

    100% {
        -webkit-box-shadow: 5px 5px 20px rgb(0, 0, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 5px 5px 20px rgba(51, 51, 51, 0.8);
        box-shadow: 5px 5px 20px rgba(51, 51, 51, 0.8);
    }

    70% {
        -moz-box-shadow: 5px 5px 20px rgba(19, 18, 18, 0.878);
        box-shadow: 5px 5px 20px rgba(19, 18, 18, 0.878);
    }

    100% {
        -moz-box-shadow: 5px 5px 20px rgb(0, 0, 0);
        box-shadow: 5px 5px 20px rgb(0, 0, 0);
    }
} */

.second__heading:hover {
    transform: translateY(-10px);
    text-shadow: 5px 5px 20px rgba(14, 14, 14, 0.7);
}
.second__heading__home {
    margin: 50px auto 50px;
}

/* .second__heading__home:hover{
    text-shadow: 5px 5px 10px rgba(255, 255, 255, 0.3);
} */

.second__para-heading {
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
    line-height: 1.5;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    display: inline;
    margin-bottom: 15px;
    letter-spacing: 1px;
    color: #f2f2f2;
}

.second__para {
    width: 95%;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #fff;
    font-size: 17px;
    line-height: 1.5;
    margin-top: 15px;
}

.second__para:not(:last-child) {
    margin-bottom: 30px;
}

.second__btn:link,
.second__btn:visited {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    padding: 3px;
    display: inline-block;
    color: #28b485e8;
    background-color: #f7f7f7;
    border-bottom: 1px solid #28b485e8;
    transition: all 0.2s;
    position: relative;
}

.second__btn:hover {
    background-color: #28b485e8;
    color: #f7f7f7;
    transform: translateY(-0.3rem);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.second__btn:active {
    transform: translateY(1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.second__btn {
    -webkit-animation: moveInBottom 0.5s ease-out 0.75s;
            animation: moveInBottom 0.5s ease-out 0.75s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}


/* second 2nd part */

.second__photo-group {
    position: relative;
    margin-top: 20px;
}

.second__photo {
    width: 55%;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
    background-origin: 20px;
    position: absolute;
    z-index: 10;
    transition: all 0.2s;
    outline-offset: 11px;
}


.second__photo:hover {
    transform: scale(1.05) translateY(-0.5rem);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
    z-index: 20;
    outline: 11px solid #28b485e3;
}

.second__photo-group:hover .second__photo:not(:hover) {
    transform: scale(0.95);
}

@media screen and (max-width: 1000px) {
    .second__heading {
        font-size: 40px;
    }
    .both-sections {
        flex-direction: column;
    }
    .second{
        padding: 50px 7% 10px;
    }
    .left-section,
    .right-section {
        width: 100%;
    }

    .second__photo {
        width: 35%;
        outline-offset: 5px;
    }

.second__para{
    text-align: justify;
}
    .both-sections div {
        padding-left: 0 !important;
    }
}

footer {
  color: #929292;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  font: inherit;
  vertical-align: baseline;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  padding: 30px 0;
  font-size: 14px;
  margin: 0;
  background-color: #808385;
}

.container1 {
  max-width: 100%;
  margin: 0 auto;
  width: 1080px;
}

.footer_inner {
  clear: both;
  float: none;
  width: 100%;
  padding: 0 15px;
  margin: 0;
  text-align: center;
}

.text_wrapper a,
.text_wrapper span,
.text_wrapper i {
  color: #e9e8e8;
}

.footer_links_outer ul {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_links_outer li {
  display: inline-block;
  margin: 3px;
  padding: 0;
}

.footer_links_outer a {
  text-decoration: none;
  transition: all 0.3s ease;
  display: block;
  padding: 0;
  text-align: center;
  font-size: 16px;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  background-color: #263036;
  color: #708e9f;
  border-color: #263036;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.footer_links_outer a:hover {
  background-color: #0eb290;
  color: #fff !important;
  border-color: #0eb290 !important;
}

.footer-icons {
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #708e9f !important;
  margin: 20px 0;
}

.footer-icons span {
  display: block;
  text-align: left;
  margin-left: 30px;
}

.fa-envelope,
.fa-compass,
.fa-phone {
  font-size: 25px;
}
.text_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .text_wrapper{
    flex-direction: column;
  }
  .footer-icons{
    flex-direction: column;
  }
  .footer-icons-container span{
    margin: 0;
    text-align: center;
  }
  .footer-icons-container a{
    margin-left: 0 !important;
  }

}
.header_outer {
  color: #929292;
  background-color: #808385;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  position: relative;
  font: inherit;
  vertical-align: baseline;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  display: block;
  width: 100%;
  border-bottom: 5px solid #524640;
  height: auto;
  z-index: 9999;
}

.header_inner {
  color: #929292;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  max-width: 90%;
  margin: 0 auto;
  width: 1080px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#site-logo {
  float: left;
  height: 100%;
  display: table;
}

#site-logo #site-logo-inner {
  display: table-cell;
  vertical-align: middle;
  height: 115px;
}

#site-logo #site-logo-inner a {
  background-color: transparent !important;
}

#site-logo.has-responsive-logo .responsive-logo-link {
  display: none;
}

#site-logo #site-logo-inner a img {
  max-height: 100px;
  width: auto;
  vertical-align: middle;
}

.icons {
  right: -2px;
  float: right;
  position: relative;
  z-index: 10;
}

.icons_inner {
  display: flex;
  align-items: center;
  height: 74px;
}

.icons_inner ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.icon {
  float: left;
  position: relative;
}

.twitter {
  background-color: #46d4fe;
  margin-right: 5px;
}

.facebook {
  margin-right: 5px;
  background-color: #37589b;
}

.youtube {
  background-color: #c4302b;
}

.icon a {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: #fff;
  line-height: 1;
  padding: 1em;
  border-radius: 50%;
  vertical-align: middle;
  transition: all 0.3s ease-out;
  text-align: center;
  letter-spacing: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: all 0.3s ease;
}

.icon svg {
  width: 1em !important;
  height: 1em;
  text-align: center;
}

.nav_outer {
  float: right;
  position: relative;
  right: 0;
}

.nav {
  list-style: none;
  margin: 0;
  padding: 0;
  touch-action: pan-y;
}

.nav li {
  float: left;
  position: relative;
  white-space: nowrap;
  font-weight: 500;
}

.header_link a {
  text-transform: uppercase;
  display: block;
  font-size: 17px;
  line-height: 74px;
  font-weight: bold;
  color: #fff;
  padding: 0 15px;
  letter-spacing: 1px;
  position: relative;
  zoom: 1;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color: transparent;
}
.header_link:hover a span {
  background-image: linear-gradient(to right, #a8ff78, #78ffd6);
  color: transparent;
  -webkit-background-clip: text;
}

.nav-arrow {
  padding-left: 6px;
  line-height: 1;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  color: #929292;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  letter-spacing: .4px;
  white-space: nowrap;
  box-sizing: border-box;
  outline: 0;
  font: inherit;
  vertical-align: baseline;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  min-width: 180px;
  line-height: 1;
  text-align: left;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: none;
  border-color: #0eb290;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
}

.menu_item:hover .dropdown_menu {
  opacity: 1 !important;
  visibility: visible !important;
}

.dropdown_item {
  color: #929292;
  word-wrap: break-word;
  letter-spacing: .4px;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  white-space: nowrap;
  border-bottom: 1px solid #f1f1f1;
  display: block;
  float: none !important;
}

.dropdown_item a {
  color: #333 !important;
  display: block;
  padding: 12px 15px;
  font-size: 12px;
  line-height: 2;
  text-transform: uppercase;
}

.dropdown_item a:hover {
  background-color: #f0f0f0;
}





.responsive_nav {
  display: none;
  position: fixed;
  left: 0;
  background-color: #808385;
  height: 100%;
  padding: 0 40px;
  z-index: 1000;
}

.mobile_nav {
  list-style: none;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-content: center;
  align-self: center;
  padding: 0;
}

.responsive_link {
  padding: 15px 0;
}

.bars {
  font-size: 24px;
  display: none
}

.close {
  font-size: 24px;
  display: none
}


.responsive_nav {
  left: -200px;
  transition: all 0.4s;
}

.responsive_nav.active_nav {
  left: 0px;
}


@media screen and (max-width: 800px) {
  .nav_outer {
    display: none
  }

  .responsive_nav {
    display: block;
  }

  .close {
    display: block
  }
  #site-logo #site-logo-inner{
    height: 100px;
  }
  #site-logo #site-logo-inner a img{
    max-height: 75px;
  }

  .bars {
    display: block;
  }
}

.categories {
    background: linear-gradient(to bottom, rgba(108, 119, 145, 0.755), hsla(222, 26%, 27%, 0.796)), url(/static/media/home2.985d9130.jpg);
    min-height: 100%;
    background-size: cover;
    background-position: 50% 0;
}

.categories-headings {
    display: flex;
}

.categories-offshore,
.categories-capital {
    width: 50%;
    text-align: center;
    padding: 0 20px;
}

.center-para {
    padding: 0 25%;
    margin-top: 10px;
    text-align: center;
}

.categories-offshore-link,
.categories-capital-link {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 800;
    color: #f7f7f7;
    transition-duration: .5s;
    font-size: 30px;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 1px;
}

.categories-offshore-link:hover,
.categories-capital-link:hover {
    transform: translateY(-5px);
    text-shadow: 2px 5px 20px rgba(255, 255, 255, 0.7);
}


.offshore-img,
.capital-img {
    width: 400px;
    display: block;
    margin: 0 auto;
}




@media screen and (max-width: 900px) {
    .center-para {
        padding: 0 0;
        text-align: left;
    }
    .right-para{
        float: right;
        text-align: right;
        
        
    }
    .left-para{
        padding: 0;
        text-align: left;

    }

    .second__para {
        width: 100%;
    }

    .categories-headings {
        flex-direction: column;
    }

    .categories-offshore {
        width: 100%;
    }

}
.contact-body {
    padding: 0px 0% 0px;
    background: linear-gradient(to left, rgba(141, 86, 33, 0.919), rgba(118, 103, 96, 0.8)), url(/static/media/contact-bg.a8b2425c.jpeg);
    min-height: 100%;
    background-size: cover;
    background-position: 50% 0;
}

.contact__heading{

    font-family: 'Josefin Sans', sans-serif;
    font-weight: 800;
    font-size: 30px;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 2px;
    background-image: linear-gradient(to right, #6bc88ae3, #28b485e8);
    color: transparent;
    -webkit-background-clip: text;
    transition-duration: .5s;
}

* {
    box-sizing: border-box;
    color: #fff;
    font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana,
      "sans-serif";
  }
  
  html,
  body {
    background: #0e101f;
    margin: 0;
    padding: 0;
  }
  
  *:focus {
    outline: 0;
  }
  
  .wrapper {
    margin: 0px auto 0;
    width: 70%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  form {
    width: 100%;
    margin: 0;
  }
  
  form * {
    font-size: 20px;
    letter-spacing: 0.075em;
    font-weight: 300;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
  }
  
  form .field {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
  }
  
  form .field label {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #00b09b, #96c93d);
    width: 100%;
    height: 64px;
    transition: width 333ms ease-in-out;
    text-align: center;
    padding: 18px 0;
  }
  
  form .field input[type="text"],
  form .field textarea {
    border: none;
    width: 100%;
    height: 64px;
    margin: 0;
    padding-left: 19.5%;
    color: #313a3d;
  }
  
  form #msg {
    height: 64px;
    resize: none;
    transition: all 333ms ease-in-out;
    padding-top: 18px;
  }
  form textarea:not(:-ms-input-placeholder)#msg {
    height: 166px;
  }
  form textarea:focus#msg,
  form textarea:not(:placeholder-shown)#msg {
    height: 166px;
  }
  form input[type="text"]:not(:-ms-input-placeholder) + label, form textarea:not(:-ms-input-placeholder) + label {
    width: 18%;
  }
  form input[type="text"]:focus + label,
  form input[type="text"]:not(:placeholder-shown) + label,
  form textarea:focus + label,
  form textarea:not(:placeholder-shown) + label,
  form .field:hover label {
    width: 18%;
  }
  form input[type="submit"] {
    background: linear-gradient(90deg, #00b09b, #96c93d);
    -webkit-appearance: none;
    border: none;
    position: relative;
    padding: 13px 50px;
    transition: all 0.3s ease-in-out;
  }
  form input[type="submit"]:hover,
  form input[type="submit"]:focus {
    background: linear-gradient(180deg, #00b09b, #96c93d);  
  }
  
  @media screen and (max-width: 900px) {
    form .field input[type="text"],
    form .field textarea {
      padding-left: 32%;
    }
    
    form input[type="text"]:not(:-ms-input-placeholder) + label, form textarea:not(:-ms-input-placeholder) + label {
      width: 30%;
    }
    
    form input[type="text"]:focus + label,
    form input[type="text"]:not(:placeholder-shown) + label,
    form textarea:focus + label,
    form textarea:not(:placeholder-shown) + label,
    form .field:hover label {
      width: 30%;
    }
    form * {
      font-size: 15px;
      text-transform: capitalize;
    }
    form .field input[type="text"], form .field textarea{
      height: 58px;
    }
    form .field label, form #msg{
      height: 58px;
    }
    
   
  }
  
.offshore-body {
    padding: 0px 0% 50px;    
    background: linear-gradient(to right, rgba(96, 56, 19, 0.919), rgba(178, 159, 148, 0.83)), url(/static/media/offshore-bg.ae9c201b.jpeg);
    min-height: 100%;
    background-size: cover;
    background-position: 50% 0;

}

@media screen and (max-width: 900px) {
    .offshore-body{
        padding: 20px 0 150px;
    }
}

.heading_of_para{
    font-weight: bold;
    font-size: 23px;
    color: azure;
}

.carousel > input[type="radio"]:nth-child(1):checked ~ .carousel__prev > label:nth-child(5), .carousel > input[type="radio"]:nth-child(1):checked ~ .carousel__next > label:nth-child(2), .carousel > input[type="radio"]:nth-child(2):checked ~ .carousel__prev > label:nth-child(1), .carousel > input[type="radio"]:nth-child(2):checked ~ .carousel__next > label:nth-child(3), .carousel > input[type="radio"]:nth-child(3):checked ~ .carousel__prev > label:nth-child(2), .carousel > input[type="radio"]:nth-child(3):checked ~ .carousel__next > label:nth-child(4), .carousel > input[type="radio"]:nth-child(4):checked ~ .carousel__prev > label:nth-child(3), .carousel > input[type="radio"]:nth-child(4):checked ~ .carousel__next > label:nth-child(5), .carousel > input[type="radio"]:nth-child(5):checked ~ .carousel__prev > label:nth-child(4), .carousel > input[type="radio"]:nth-child(5):checked ~ .carousel__next > label:nth-child(1) {
    opacity: 1 !important;
    z-index: 3;
  }
  

  .container {
    width: 900px;
    min-width: 900px;
    margin: 50px auto;
  }
  
  .carousel {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .carousel > input[type="radio"] {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
  }
  .carousel > input[type="radio"]:checked ~ .carousel__items .carousel__item,
  .carousel > input[type="radio"]:checked ~ .carousel__prev > label,
  .carousel > input[type="radio"]:checked ~ .carousel__next > label {
    opacity: 0;
  }
  .carousel > input[type="radio"]:nth-child(1):checked ~ .carousel__items .carousel__item:nth-child(1) {
    opacity: 1;
  }
  .carousel > input[type="radio"]:nth-child(1):checked ~ .carousel__nav > label:nth-child(1) {
    background: #ccc;
    cursor: default;
    pointer-events: none;
  }
  .carousel > input[type="radio"]:nth-child(2):checked ~ .carousel__items .carousel__item:nth-child(2) {
    opacity: 1;
  }
  .carousel > input[type="radio"]:nth-child(2):checked ~ .carousel__nav > label:nth-child(2) {
    background: #ccc;
    cursor: default;
    pointer-events: none;
  }
  .carousel > input[type="radio"]:nth-child(3):checked ~ .carousel__items .carousel__item:nth-child(3) {
    opacity: 1;
  }
  .carousel > input[type="radio"]:nth-child(3):checked ~ .carousel__nav > label:nth-child(3) {
    background: #ccc;
    cursor: default;
    pointer-events: none;
  }
  .carousel > input[type="radio"]:nth-child(4):checked ~ .carousel__items .carousel__item:nth-child(4) {
    opacity: 1;
  }
  .carousel > input[type="radio"]:nth-child(4):checked ~ .carousel__nav > label:nth-child(4) {
    background: #ccc;
    cursor: default;
    pointer-events: none;
  }
  .carousel > input[type="radio"]:nth-child(5):checked ~ .carousel__items .carousel__item:nth-child(5) {
    opacity: 1;
  }
  .carousel > input[type="radio"]:nth-child(5):checked ~ .carousel__nav > label:nth-child(5) {
    background: #ccc;
    cursor: default;
    pointer-events: none;
  }
  .carousel__items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    height: 600px;
    position: relative;
  }
  .carousel__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 2s;
    -webkit-transition: opacity 2s;
  }
  .carousel__item img {
    width: 100%;
    vertical-align: middle;
  }
  .carousel__prev > label, .carousel__next > label {
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    opacity: 0;
    z-index: 2;
  }
  .carousel__prev > label:hover, .carousel__prev > label:focus, .carousel__next > label:hover, .carousel__next > label:focus {
    opacity: .5 !important;
  }
  .carousel__prev > label:before, .carousel__prev > label:after, .carousel__next > label:before, .carousel__next > label:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
  }
  .carousel__prev > label:before, .carousel__next > label:before {
    background: linear-gradient(to top, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10%), linear-gradient(to left, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10%);
    width: 60%;
    height: 60%;
    top: 20%;
  }
  .carousel__prev > label {
    left: 2%;
  }
  .carousel__prev > label:before {
    left: 35%;
    top: 20%;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .carousel__next > label {
    right: 2%;
  }
  .carousel__next > label:before {
    left: 10%;
    transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
  }
  .carousel__nav {
    position: absolute;
    bottom: 3%;
    left: 0;
    text-align: center;
    width: 100%;
    z-index: 3;
  }
  .carousel__nav > label {
    border: 1px solid #fff;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 .125%;
    width: 20px;
    height: 20px;
  }
    












.capital-body {
    padding: 0px 0% 50px;
    background: linear-gradient(to bottom, rgba(146, 88, 36, 0.898), rgba(155, 138, 128, 0.885)), url(/static/media/capital-bg.620abc2d.jpeg);
    min-height: 100%;
    background-size: cover;
    background-position: 50% 0;
}



@media screen and (max-width: 900px) {
    .capital-body{
        padding: 20px 0 150px;
    }
}
