.header_outer {
  color: #929292;
  background-color: #808385;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  position: relative;
  font: inherit;
  vertical-align: baseline;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  display: block;
  width: 100%;
  border-bottom: 5px solid #524640;
  height: auto;
  z-index: 9999;
}

.header_inner {
  color: #929292;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  max-width: 90%;
  margin: 0 auto;
  width: 1080px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#site-logo {
  float: left;
  height: 100%;
  display: table;
}

#site-logo #site-logo-inner {
  display: table-cell;
  vertical-align: middle;
  height: 115px;
}

#site-logo #site-logo-inner a {
  background-color: transparent !important;
}

#site-logo.has-responsive-logo .responsive-logo-link {
  display: none;
}

#site-logo #site-logo-inner a img {
  max-height: 100px;
  width: auto;
  vertical-align: middle;
}

.icons {
  right: -2px;
  float: right;
  position: relative;
  z-index: 10;
}

.icons_inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 74px;
}

.icons_inner ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.icon {
  float: left;
  position: relative;
}

.twitter {
  background-color: #46d4fe;
  margin-right: 5px;
}

.facebook {
  margin-right: 5px;
  background-color: #37589b;
}

.youtube {
  background-color: #c4302b;
}

.icon a {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: #fff;
  line-height: 1;
  padding: 1em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  text-align: center;
  letter-spacing: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.icon svg {
  width: 1em !important;
  height: 1em;
  text-align: center;
}

.nav_outer {
  float: right;
  position: relative;
  right: 0;
}

.nav {
  list-style: none;
  margin: 0;
  padding: 0;
  touch-action: pan-y;
}

.nav li {
  float: left;
  position: relative;
  white-space: nowrap;
  font-weight: 500;
}

.header_link a {
  text-transform: uppercase;
  display: block;
  font-size: 17px;
  line-height: 74px;
  font-weight: bold;
  color: #fff;
  padding: 0 15px;
  letter-spacing: 1px;
  position: relative;
  zoom: 1;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color: transparent;
}
.header_link:hover a span {
  background-image: linear-gradient(to right, #a8ff78, #78ffd6);
  color: transparent;
  -webkit-background-clip: text;
}

.nav-arrow {
  padding-left: 6px;
  line-height: 1;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  color: #929292;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  letter-spacing: .4px;
  white-space: nowrap;
  box-sizing: border-box;
  outline: 0;
  font: inherit;
  vertical-align: baseline;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  min-width: 180px;
  line-height: 1;
  text-align: left;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: none;
  border-color: #0eb290;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
}

.menu_item:hover .dropdown_menu {
  opacity: 1 !important;
  visibility: visible !important;
}

.dropdown_item {
  color: #929292;
  word-wrap: break-word;
  letter-spacing: .4px;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  white-space: nowrap;
  border-bottom: 1px solid #f1f1f1;
  display: block;
  float: none !important;
}

.dropdown_item a {
  color: #333 !important;
  display: block;
  padding: 12px 15px;
  font-size: 12px;
  line-height: 2;
  text-transform: uppercase;
}

.dropdown_item a:hover {
  background-color: #f0f0f0;
}





.responsive_nav {
  display: none;
  position: fixed;
  left: 0;
  background-color: #808385;
  height: 100%;
  padding: 0 40px;
  z-index: 1000;
}

.mobile_nav {
  list-style: none;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-content: center;
  align-self: center;
  padding: 0;
}

.responsive_link {
  padding: 15px 0;
}

.bars {
  font-size: 24px;
  display: none
}

.close {
  font-size: 24px;
  display: none
}


.responsive_nav {
  left: -200px;
  transition: all 0.4s;
}

.responsive_nav.active_nav {
  left: 0px;
}


@media screen and (max-width: 800px) {
  .nav_outer {
    display: none
  }

  .responsive_nav {
    display: block;
  }

  .close {
    display: block
  }
  #site-logo #site-logo-inner{
    height: 100px;
  }
  #site-logo #site-logo-inner a img{
    max-height: 75px;
  }

  .bars {
    display: block;
  }
}
