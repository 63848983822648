@import "https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap";

body {
  font-family: 'ABeeZee', sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 1000px) {
  .App-logo{
    height: 50vmin;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    -webkit-animation: App-logo-spin infinite 20s linear;
  }

}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}