footer {
  color: #929292;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  font: inherit;
  vertical-align: baseline;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  padding: 30px 0;
  font-size: 14px;
  margin: 0;
  background-color: #808385;
}

.container1 {
  max-width: 100%;
  margin: 0 auto;
  width: 1080px;
}

.footer_inner {
  clear: both;
  float: none;
  width: 100%;
  padding: 0 15px;
  margin: 0;
  text-align: center;
}

.text_wrapper a,
.text_wrapper span,
.text_wrapper i {
  color: #e9e8e8;
}

.footer_links_outer ul {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_links_outer li {
  display: inline-block;
  margin: 3px;
  padding: 0;
}

.footer_links_outer a {
  text-decoration: none;
  transition: all 0.3s ease;
  display: block;
  padding: 0;
  text-align: center;
  font-size: 16px;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  background-color: #263036;
  color: #708e9f;
  border-color: #263036;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.footer_links_outer a:hover {
  background-color: #0eb290;
  color: #fff !important;
  border-color: #0eb290 !important;
}

.footer-icons {
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #708e9f !important;
  margin: 20px 0;
}

.footer-icons span {
  display: block;
  text-align: left;
  margin-left: 30px;
}

.fa-envelope,
.fa-compass,
.fa-phone {
  font-size: 25px;
}
.text_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .text_wrapper{
    flex-direction: column;
  }
  .footer-icons{
    flex-direction: column;
  }
  .footer-icons-container span{
    margin: 0;
    text-align: center;
  }
  .footer-icons-container a{
    margin-left: 0 !important;
  }

}