.capital-body {
    padding: 0px 0% 50px;
    background: linear-gradient(to bottom, rgba(146, 88, 36, 0.898), rgba(155, 138, 128, 0.885)), url("../../images/capital-bg.jpeg");
    min-height: 100%;
    background-size: cover;
    background-position: 50% 0;
}



@media screen and (max-width: 900px) {
    .capital-body{
        padding: 20px 0 150px;
    }
}